@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Regular.woff2') format('woff2'),
      url('../src/assets/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Light.woff2') format('woff2'),
      url('../src/assets/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Medium.woff2') format('woff2'),
      url('../src/assets/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('../src/assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('../src/assets/fonts/Poppins-Bold.woff2') format('woff2'),
      url('../src/assets/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'Magzo Semi';
    src: url('../src/assets/fonts/Magzo-SemiBold.woff2') format('woff2'),
        url('../src/assets/fonts/Magzo-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FA6400; 
  border-radius: 2px;
}
.container-width {
  width: 100%;
  max-width: 1644px;
  margin:  0 auto;
}
.wrapper {
  overflow-x: hidden;
}

h1 {
  font-family: 'Magzo Semi';
  font-size: 92px;
  font-weight: 600;
}
h2 {
  font-family: 'Magzo Semi';
  font-size: 46px;
  font-weight: 600;
}
h3 {
  font-family: 'Magzo Semi';
  font-size: 40px;
  font-weight: 600;
}
h4 {
  font-family: 'Magzo Semi';
  font-size: 32px;
  font-weight: 600;
}
p {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
}
.orange-btn, .btn-transparent, .btn-white, .btn-transparent-popup{
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  border-radius: 5px;
  background-color: #FA6400;
  color: white;
  border: 1px solid #FA6400;
  padding: 13px 30px;
  transition: all ease-in-out 0.7s;
}
.btn-transparent-popup{
 border: 1px solid #FA6400;
 color: #FA6400;
 background-color: #fff;
 padding: 11px 26px 11px 24px;  
}
.orange-btn:hover, .orange-btn:active, .orange-btn:focus {
  background-color: #263B4E !important;
  border-color: #263B4E !important;
  color: #fff !important;
}
.btn-transparent {
  background-color: transparent;
  color: #FA6400;
}
.btn-white{
  background-color: #FFFFFF;
  color: #FA6400;
  border: 1px solid #FFFFFF !important;
}
.btn-transparent:hover, .btn-transparent:active, .btn-transparent:focus, .btn-white:hover, .btn-transparent-popup:hover, .btn-white:focus, .btn-white:active {
  border-color: #FA6400 !important;
  background-color: #FA6400 !important;
  color: white !important;
}
.orange-btn.mobile-view{display: none;}
/* header */
.header {
  padding: 16px 0px;
  background-color: white;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 1px 1px 12px 2px #ccc;
}
.mobile-dd {
  display: none;
}
.dollar-icon {
  background-color: #263B4E;
  padding: 7px 10px;
  border-radius: 50%;
  font-size: 14px;
  color: white;
}
.header-search-select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #0000001A;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  padding: 6px;
  width: 746px;
}
.header-search-select .select-search-container {
  width: 100%;
  --select-search-selected: #fff;
}
.header-search-select .select-search-input {
  border: 1px dashed #C9C9C9;
  border-radius: 3px;
}
.header-search-select .select-search-input:hover {
  border-color: #C9C9C9 !important;
}
.header-search-select .select-search-options {
  padding: 8px;
  margin: 0px;
}
.header-search-select .select-search-is-selected {
  color: #fa6400 !important;
}
.drop-curr, .dropdowns-wrapper .drop-language-wrapper h6 {
  margin-right: 55px;
}
.dropdowns-wrapper .drop-curr-wrapper, .dropdowns-wrapper .drop-language-wrapper {
  background-color: white;
  border: 1px solid #C3C3C3;
  color: #263B4E;
  padding: 12px 8px;
  font-size: 15px;
  font-family: 'Poppins';
  font-weight: 500;
}
.dropdowns-wrapper .drop-language-wrapper {
  background-color: #263B4E;
  color: white;
  padding: 17px 12px;
}
.dropdowns-wrapper .drop-language-wrapper:hover, .dropdowns-wrapper .drop-language-wrapper:focus, .dropdowns-wrapper .drop-language-wrapper:active {
  background-color: #263B4E;
  color: white;
}
.dropdowns-wrapper .drop-curr-wrapper:hover, .dropdowns-wrapper .drop-curr-wrapper:focus, .dropdowns-wrapper .drop-curr-wrapper:active {
  background-color: white;
  color: #263B4E;
  border-color: #C3C3C3;
}
.dropdowns-wrapper .drop-curr-wrapper::after, .dropdowns-wrapper .drop-language-wrapper::after {
  background-image: url('../src/assets/images/angle-down-black.svg');
  background-repeat: no-repeat;
  border: unset;
  width: 12px;
  height: 12px;
  position: absolute;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.dropdowns-wrapper .drop-language-wrapper::after {
  background-image: url('../src/assets/images/angle-down-white.svg');
}
.header .dropdown-item, .dropdown-menu.show{border-radius: 0;} 
.header .header-content .dropdowns-wrapper .dropdown-menu.show {top: 20px !important; border: unset;box-shadow: 0px 1px 6px #00000029; width: 300px; left: -160px !important; color: #263B4E;}
.drop-curr {
  color: #263B4E;
}
.plans-table-wrapper .dropdowns-wrapper .dropdown-menu.show {top: unset !important; border: unset;box-shadow: 0px 1px 6px #00000029; width: 300px;color: #263B4E; z-index: 1;}

.header-btn-color.dropdown-menu.show{
  background-color: #263B4E;
}
.header-btn-color .dropdown-item h6 {
  color: white;
}
.dropdowns-wrapper .dropdown-item h6 {
  font-size: 16px;
}
.dropdowns-wrapper .dropdown-menu.show .dropdown-item {
  padding: 8px 15px;
}
.dropdwon-top-clip-img {
    position: absolute;
    top: -20px;
    right: 27px;
}
/* .header-btn-color .dropdown-item h6:hover{color: #000;} */
.header-btn-color .dropdown-item:hover h6{color: #000;}
.header-search-select .css-13cymwt-control, .css-1fdsijx-ValueContainer, .custom__control  {
  padding: 5px 40px;

}
.banner .banner-content .banner-form .input-wrapper-area .custom__control  {
  padding: 0;
}
.header-search-select .css-13cymwt-control{
  border: 1px dashed #C9C9C9;
}
.header-search-select .custom__control :hover{
  border-color: unset;
}

/* .header-country-search{
  position: absolute;
    left: 0;
    right: 0;
    background:#FFFFFF 0% 0% no-repeat padding-box;
    padding: 6px;
    top: -30px;
    border-radius: 10px;
    box-shadow: 0px 0px 9px #0000001a;
} */
.header-search-form {
  border: 1px dashed #C9C9C9;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}
.header-search-form input{
  border: 0;
  cursor: pointer;
}
.header-search-form .form-control:focus {
  border-color: unset;
  box-shadow: unset;
}
.header-search-select .css-1hb7zxy-IndicatorsContainer{
  display: none;
}
.header-search-select .custom__control {border-color: unset; box-shadow: none;}
.header-search-img {
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  z-index: 2;
}
.header-search-icon{
  position: absolute;
  left: 20px;
  top: 15px;
}
/* .header-search-form img{
  width: 40px;
} */
.header-flag-img{
  width: 35px;
}
.header-flag-img img{
  width: 100%;
  height: 100%;
}

.form-control:disabled{background-color: unset;}
.header-search-form span{
  color: #FE6C30;
}
.header .header-content .search-img{
  position: absolute;
  top: 15px;
  left: 22px;
  z-index: 1;
}
.header-search-select.mobie-view{
  display: none;
}
.country-search, .country-search-header {
  padding: 18px 10px;
  background: #fff;
  border: 1px dashed #5ABB90;
  border-radius: 4px;
}
.country-search-header{
  border: 1px dashed #C9C9C9;
}
.header-custom__control{
  padding: 5px 40px;
  border: 1px dashed #C9C9C9;
  box-shadow: none !important;
  border-color: #C9C9C9 !important;
  outline: unset !important;
}

.header-custom__indicators.css-1wy0on6{
  display: none;
}



/* banner */
.banner {
  background-image: url('../src/assets/images/banner-img.png');
  background-repeat: no-repeat;
  padding-top: 160px;
  height: 878px;
  margin-top: 90px;
  background-size: cover;
}
.orange {
  color: #FA6400 !important;
}
.banner-content h1 {
  margin-bottom: 65px;
  color: #263B4E;
}
.banner-content h3 {
  color: #263B4E;
}
.banner-form {
  max-width: 1103px;
  width: 100%;
  margin: 0 auto;
}
.banner-form .input-wrapper-area {
  background-color: #263B4E;
  border-radius: 10px;
  padding: 10px;
}
.banner-form .select-search-container {
  width: 100%;
  --select-search-selected: #fff;
}
.banner-form input {
  background-color: #263B4E;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: normal;
  border: 1px dashed #5ABB90;
  padding: 20px 10px 20px 100px;
  color: #fff !important;
  height: unset;
}
.banner-form input::placeholder {
  color: #ffffffc7;
}
.banner-form .select-search-container:not(.select-search-is-multiple) .select-search-select {
  top: 92px;
}
.banner-form .select-search-input:hover {
  border-color: #5ABB90 !important;
}
.banner-form .select-search-options {
  padding: 8px;
  margin: 0px;
}
.banner-form .select-search-is-selected {
  color: #fa6400 !important;
}
.banner-search-img {
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  z-index: 2;
}
/* about */
.about-home {
  padding: 40px 0 50px;
}
.about {
  padding: 160px 0px 50px;
}
.about-content h2 {
  margin-bottom: 60px;
  color: #263B4E;
}
.about-content h2::before {
  content: '';
  position: absolute;
  background-image: url('../src//assets/images/before-img.svg');
  background-repeat: no-repeat;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  height: 16px;
  width: 203px;
}
.about-content p, .privacy-info-content p, .esim-description {
  font-size: 16px ;
  font-family: 'Poppins';
  font-weight: normal;
  color: #000000;
}


/* about info */
.about-boxes-wrapper {
  padding: 10px 0px 100px;
}
.about-boxes {
  background-color: white;
  box-shadow: 1px 1px 6px 6px #0000000d;
  padding: 30px 35px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.about-boxes:nth-child(even) {
  background-color: #FFFDFA;
}
.about-boxes-content h4 {
  color: #263B4E;
}
.about-boxes-content p {
  color: black;
  margin-bottom: 30px;
}
.about-country-flags {
  border: 1px solid #D6D6D6;
  border-radius: 7px;
  padding: 5px 14px;
  margin-bottom: 15px;
  transition: all ease-in-out 0.5s;
  height: 50px;
}
.about-country-flags:hover {
  box-shadow: 0px 0px 10px #0000001A;
  border: 1px solid #D6D6D6;
  cursor: pointer;
}
.about-country-flags:hover p {
  padding-left: 10px;
}
.about-country-flags:last-child {
  margin-bottom: 0px;
}
.about-country-flags p {
  color: #263B4E;
  margin-bottom: 0px;
  width: calc(100% - 45px);
  transition: all ease-in-out 0.5s;
}
.about-country-flags-img, .about-country-flags-img-popups {
  border-radius: 4px;
  box-shadow: 1px 1px 6px 7px #0000000d;
  margin-left: 8px;
  width: 45px;
}
.about-country-flags-img-globel {
  width: 31px;
  border-radius: 4px;
  box-shadow: unset;
}
.about-country-flags-img-globel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.about-country-flags-img img, .about-country-flags-img-popups img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}
.about-country-flags-img-popups{width: 34px;}
.about-region-flag {
  border-radius: 50%;
}
.about-boxes-wrapper a, .mogo-data-boxes-content a, .about a {
  text-decoration: none;
}
.box-heading{
  padding: 0 35px;
  color: #263b4e;
}
/* footer */
.footer {
  background-color: #263B4E;
  padding-top: 50px;
}
.social-icons-img, .social-icons-img-banner {
  background-color: #435E77;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  transition: all ease-in-out .8s;
}
.social-icons-img:hover{
  transform: scale(1.1);
  border-radius: 4px;
  background-color: #FA6400;
}
.social-icons-img-banner{background-color: #FE6C30;}
.social-icons-img-banner:hover{background-color: #435E77; transform: scale(1.1);
  border-radius: 4px;
}
.footer-social {
  margin-bottom: 60px;
}
.footer-social a {
  margin-right: 25px;
}
.footer-social a:last-child {
  margin-right: 0px;
}
.footer-links {
  margin-bottom: 45px;
}
.footer-links a {
  text-decoration: none;
}
.footer-links h6 {
  color: white;
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: normal;
  margin-right: 90px;
  margin-bottom: 0px;
}
.footer-links h6:hover {
  color: #FA6400;
}
.footer-links .content-border {
  position: relative;
}
.footer-links .content-border::before {
  content: '';
  position: absolute;
  height: 16px;
  width: 1px;
  background-color: #ffffff63;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.footer-content p {
  font-size: 16px;
  color: #ffffff63;
  font-family: 'Poppins';
  font-weight: normal;
  width: 1243px;
  margin: 0 auto 40px;
}
.footer-copyright {
  border-top: 1px solid #ffffff2e;
  padding: 20px 0px;
}
.footer-copyright p {
  font-size: 16px;
  color: #ffffff63;
  font-family: 'Poppins';
  font-weight: 300;
}

/* plans */
.plans-banner {
  background-image: url('../src//assets//images/provider-banner-img.png');
  background-size: cover !important;
  margin-top: 90px !important;
  height: 434px !important;
}
/* .second-banner {
  background-repeat: no-repeat;
  padding: 157px 0px;
  background-size: 100% 100%;
} */
.second-banner {
  background-repeat: no-repeat;
  padding: 30px 0px;
  background-size: 100%;
  height: 544px;
  display: flex;
  background-position: center;
  margin-top: 80px;
  align-items: center;
}
.second-banner-content {
  width: 780px;
  margin: 0 auto;
}
.second-banner-content p {
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 20px;
  color: black;
  line-height: 34px;
}

.plans-dropdowns .dropdown-menu.show {
  left: -90px !important;
}
.checked-tick
{width: 31px;
}
.plan-offers-content-area-bottom h6 {
  width: calc(100% - 31px);
}
/* plans slider */
.plans-slider {
  padding: 60px 0px;
}
.plans-slider-wrapper .slick-track {
  padding-top: 10px;
}
.sr-item-content {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFF8F8 100%);
  box-shadow: 0px 11px 16px #fd4b4b12;
  border: 1px solid #D6D6D6;
  border-radius: 11px;
  margin: 0px 12px;
}
.sr-item-content-two {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #F7FFFB 100%);
}
.sr-item-content-three {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%);
}
.sr-item-top {
  background-color: #FA6400;
  width: 350px;
  margin: 0 auto;
  border-radius: 0px 0px 10px 10px;
  padding: 6px 0px;
  top: -7px;
}
.tri-left-img {
  display: inline-flex;
  top: 0;
  left: -6px;
}
.tri-right-img {
  display: inline-flex;
  top: 0;
  right: -6px;
}
.sr-item-top span {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 20px;
  color: white;
}
.sr-item-content-info {
  padding: 40px 0px;
}
.sr-item-content-info h2 {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 75px;
  color: #263B4E;
}
.sr-item-content-info h6 {
  font-family: 'Poppins';
  font-weight: normal;
  color: #5ABB90;
  font-size: 20px;
  margin-bottom: 27px;
}
.slick-prev:before, .slick-next:before {
  display: none;
}
.plans-slider-wrapper .slick-next, .plans-slider-wrapper .slick-prev {
  width: 23px;
  height: 23px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  padding: 7px;
  z-index: 30;
}
.plans-slider-wrapper .slick-next path, .plans-slider-wrapper .slick-prev path {
  fill: black;
}
.plans-slider-wrapper .slick-next:hover, .plans-slider-wrapper .slick-prev:hover {
  background-color: #FA6400;
}
.plans-slider-wrapper .slick-next:hover path, .plans-slider-wrapper .slick-prev:hover path {
  fill: white;
}
.slick-next {
  right: -8px;
}
.slick-prev {
  left: -8px;
}
.plans-slider-wrapper a {
  text-decoration: none;
}
/* plans table */
.plans-table-info p span {
  font-weight: bold;
}
.plans-dropdowns .drop-curr-wrapper {
  padding: 12px 14px;
}
.plans-dropdowns .drop-curr {
  margin-right: 40px;
}
.plans-table {
  background: #FFFFFF;
  box-shadow: 0px 3px 16px #0000000D;
  border-radius: 6px;
  margin-bottom: 90px;
}
.plans-table .table {
  --bs-table-hover-bg: #FFF9F5;
  cursor: pointer;
  margin-bottom: 0px;
}
.plans-table th {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #263B4E;
  padding: 25px;
}
.plans-table td {
  font-size: 18px;
  color: #263B4E;
  font-family: 'Poppins';
  font-weight: normal;
  padding: 15px 25px;
}
.plans-table tr:last-child td {
  border-bottom: unset;
}
.plans-table-img {
  width: 45px;
  height: 27px;
}
.plans-table-img, .plans-table-img img {
  border-radius: 0;
}
.plans-table-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.plans-dropdowns .plans-custom-dropdown {
  padding: 25px 20px;
  overflow: hidden;
}
.user-site-pagination .page-link {
  color: #fa6400;
}
.user-site-pagination .first-pagination .page-link , .user-site-pagination .last-pagination .page-link  {
  background-color: #fa6400;
  color: white;
}
.user-site-pagination .page-link:focus {
  box-shadow: unset;
}
.user-site-pagination .pagination {
  --bs-pagination-active-bg: #fff;
  --bs-pagination-active-border-color: #dee2e6;
  margin-bottom: 0px;
  padding: 50px 0px;
}
.user-site-pagination .active > .page-link, .user-site-pagination .page-link.active {
  background-color: #fa6400;
  border-color: #fa6400;
  color: white;
}
.table-tabs li.nav-item button, ul.nav.nav-tabs{
  border: 0;
}
.button-group-select {
  border-radius: 10px;
  background-color: #e9eaf4;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px;
  max-width: 500px;
}
.button-group-select button{
  color: #514d6d;
  padding: 0 18px;
  border: none;
  display: inline-block;
  background: none;
  white-space: nowrap;
  height: 100%;
  border-radius: 10px;
}
.button-group-select button.active {
  background: #fff;
  color: #FE6C30;
}
.mobile-view-provider-list{
  display: none;
}
/* privacy */
.privacy-banner, .terms-banner {
  background-image: url('../src//assets//images//privacy-banner.png');
  /* padding: 172px 0px; */
  background-position: center;
}
.privacy-banner {
  background-size: unset;
}
/* privacy info */
.privacy-info {
  padding: 80px 0px;
}
.privacy-info p a{
  text-decoration: none;
    color: #FA6400;
}
.privacy-info-content h2, .privacy-boxes-content h4, .privacy-legal h4, .privacy-collection-use h4, .privacy-cookies-info h4, .control-cookies h4 , .privacy-legal ul li, .privacy-list li, .mogo-banner-content h2 {
  color: #001558;
}
.control-cookies h5
{
  color: #001558;
}
.privacy-boxes-content {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 10px;
  padding: 35px 30px 35px 45px;
  position: relative;
  height: 100%;
}
.privacy-boxes-content::before, .privacy-legal ul::before {
  content: '';
  position: absolute;
  background-color: #FA6400;
  height: 92%;
  width: 3px;
  top: 50%;
  transform: translateY(-50%);
  left: 7px;
}
.privacy-legal-boxe {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #F4FAFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #0000001A;
  border-radius: 10px;
  padding: 30px;
}
.privacy-legal h4 {
  margin-bottom: 35px;
}
.privacy-legal ul {
  position: relative;
  padding-left: 20px !important;
}
/* .privacy-legal ul::before {
  height: 82%;
} */
.privacy-legal ul li, .privacy-list li {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  list-style: none;
  line-height: 40px;
}
.cookie-policy {
  background: transparent linear-gradient(180deg, #FE6C30 0%, #FE6C30 100%);
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 10px;
  padding: 30px 40px;
}
.cookie-policy-content h4, .cookie-policy-content p {
  color: white;
}
.cookie-policy .form-switch .form-check-input:checked {
  background-image: url('../src/assets/images/check.svg');
  background-repeat: no-repeat;
  background-color: white;
  border-color: white;
  cursor: pointer;
}
.cookie-policy .form-switch .form-check-input {
  width: 110px;
  height: 50px;
  background-image: url('../src//assets/images/check-white.svg');
  background-repeat: no-repeat;
  background-color: #5ABB90;
  border-color: #5ABB90;
  cursor: pointer;
}
.cookie-policy .form-switch .form-check-input:checked:focus {
  border-color: white;
  box-shadow: unset;
}
.cookie-policy .form-switch .form-check-input:focus {
  box-shadow: unset;
}
.privacy-legal-boxe p a {
  text-decoration: none;
  color: #FA6400;
  word-break: break-all;
}

/* mogo esim */
.orange-btn-large {
  background-color: #FA6400;
  border: 2px solid #FA6400;
  border-radius: 5px;
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 500;
  padding: 10px 28px;
  transition: all ease-in-out 0.7s;
  color: white;
}
.orange-btn-large:hover, .orange-btn-large:active, .orange-btn-large:focus {
  background-color: #263B4E !important;
  border-color: #263B4E !important;
  color: white !important;
}
/* banner */
.mogo-banner {
  background-image: url('../src//assets/images/plans-banner-img.png');
  padding: 120px 0px;
}
.mogo-banner-content {
  width: calc(100% - 71px);
}
.mogo-banner-icon {
  padding-top: 16px;
  width: 100px;
}
.mogo-banner-content h2 {
  font-size: 80px;
  font-family: 'Magzo Semi';
  font-weight: 600;
  color: #263B4E;
  line-height: 85px;
}
.mogo-banner-content h6, .mogo-banner-content p {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: normal;
  margin-bottom: 25px;
}
.mogo-banner-content p {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 40px;
}
/* mogo data boxes */
.mogo-data-boxes {
  padding: 70px 0px;
}
.mogo-data-boxes-content-heading {
  font-size: 25px;
  font-family: 'Magzo Semi';
  font-weight: 600;
  color: #263B4E;
  margin-bottom: 25px;
  display: block;
}
.mogo-data-boxes-content {
  background: #FFFFFF;
  box-shadow: 0px 6px 16px #0000001a;
  border-radius: 10px;
  padding: 30px 40px;
}
.mogo-data-boxes-content h6 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 26px;
  color: #263B4E;
}
.mogo-data-boxes-content p {
  font-size: 20px ;
  font-family: 'Poppins';
  font-weight: normal;
  color: #000000;
}
.mogo-data-boxes-content p span {
  font-size: 36px;
  font-family: 'Poppins';
  font-weight: bold;
  margin-right: 25px;
  position: relative;
}
.mogo-data-boxes-content p span::before {
  content: '';
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FA6400;
  height: 14px;
  width: 2px;
}
.mogo-data-boxes-content-info a:hover p {
  color: #FE6C30;
}
.mogo-data-boxes-content-info a:hover .exclamation-icon {
  padding-left: 8px;
}
.mogo-data-boxes-content-info p {
  font-size: 16px;
  color: #001558;
  font-family: 'Poppins';
  font-weight: normal;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
}
.mogo-data-boxes-content-info .exclamation-icon {
  color: #FA6400;
  transition: all ease-in-out 0.5s;
}
.mogo-data-boxes-price {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%);
  background-color: #EBEBEB;
  right: 0;
  bottom: 30px;
  padding: 8px 30px 8px 35px;
}
.mogo-data-boxes-price span {
  font-size: 26px;
  color: #001558;
  font-family: 'Poppins';
  font-weight: bold;
}
/* mogo plan buy */
.plan-buy {
  padding-bottom: 90px;
}
.plan-buy-box-content {
  /* background-image: url('../src//assets/images/plan-buy-img-one.svg');
  background-repeat: no-repeat;
  width: 25%;
  background-size: inherit; */
  padding: 30px 35px 40px 35px;
  background-position: left bottom;
  border: 1px solid #00000012;
  height: auto;
  min-height: 440px;
}
/* .plan-buy-box-content.green {
  background-image: url('../src//assets/images/plan-buy-img-two.svg');
}
.plan-buy-box-content:last-child {
  background-image: url('../src//assets/images/plan-buy-img-three.svg');
} */
.plan-buy-box-content-top-img {
  box-shadow: 4px 12px 6px 2px #8080804d;
  border-radius: 50%;
}
.plan-buy-box-content-top {
  left: 55px;
  top: -17px;
}
.plan-buy-box-content-top h4 {
  color: #263B4E;
  font-family: 'Poppins';
  font-weight: 600;
}
.plan-buy-box-content-bottom h6 {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
}
/* plan offers */
.plan-offers {
  padding-bottom: 60px;
}
.plan-offers-content-area {
  width: 1048px;
  margin: 0 auto;
}
.plan-offers-content-area-top a {
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}
.plan-offers-content-area-top a:last-child {
  margin-bottom: 50px;
}
.plan-offers-content {
  background: #FFFFFF;
  box-shadow: 0px 3px 10px #00000012;
  padding: 20px 40px;
  transition: all ease-in-out 0.7s;
}
.plan-offers-content:hover {
  transform: scale(1.04);
}
.plan-buy-box-content-bottom p, .plan-offers-content p, .plan-offers-content-right p {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: normal;
  margin-bottom: 0px;
  color: #263B4E;
}
.plan-buy-box-content.green .plan-buy-box-content-top h4, .plan-buy-box-content.green .plan-buy-box-content-bottom h6, .plan-buy-box-content.green .plan-buy-box-content-bottom p {
  color: #263B4E;
}
.plan-offers-content-left-img {
  width: 55px;
  height: 55px;
}
.plan-offers-content-left-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* .plan-offers-content-left-img, .plan-offers-content-left-img img {
  border-radius: 50%;
} */
.plan-offers-content-area-bottom h6 {
  font-size: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #263B4E;
}
.mogo-banner-img-mobile {
  display: none;
}
.sr-item .sr-item-content .sr-img-one{
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.sr-item .sr-item-content .sr-img-one img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-right.slick-arrow.slick-next {
  color: #fff;
  background: #263B4E;
  padding: 15px;
  border-radius: 30px;
  top: 250px;
  right: -50px;

}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-left.slick-arrow.slick-prev{
  color: #fff;
  background: #263B4E;
  padding: 15px;
  border-radius: 30px;
  left: -70px;
  top: 250px;
  z-index: 1;
}
.plan-buy .pro-slider .slick-dots li.slick-active button:before{
  font-size: 10px;
  color:#263B4E ;

}
.pro-slider .slick-slide{
  margin: 0 10px;
}
.pro-slider .slick-track {
  margin: 0 -10px;
  display: flex;
}
/* terms */
/* banenr */
.terms-banner {
  background-image: url('../src/assets/images/terms-banner.png');
  background-size: inherit;
}
.terms-info .privacy-boxes-content::before {
  background-color: unset;
}
.privacy-boxes-content-img {
  width: 60px;
}
.privacy-boxes-content-info {
  width: calc(100% - 60px);
}
.terms-legal h2 {
  color: #001558;
}
.terms-legal h4 {
  margin-bottom: 15px;
}
.spinner svg {
  margin: 0 auto;
 
}
.banner .banner-form .input-wrapper-area .css-1hb7zxy-IndicatorsContainer {
  display: none;
}
.banner-form .input-wrapper-area .css-13cymwt-control, .banner-form .input-wrapper-area .custom__control  {
  padding: 25px 45px 25px 90px !important;
  text-align: left !important;
  background-color: #263B4E;
  border: 1px dashed #5ABB90;
  cursor: pointer;
  box-shadow: none;
}
.custom__option {
  background: #fff !important;
}
.custom__option:hover{
  background-color: #263B4E !important;

}
.header-custom__option{
  background-color: #fff !important;
}
.header-custom__option:hover{
  background-color: #263B4E !important;
}

/* support */
.support-banner-content p {
  font-size: 20px !important; 
  color: #000000;
}
.support-banner{background-image: url("../src/assets/images/support-banner.svg");    background-position: initial;
  background-size: cover !important;
}
.support-banner-mark {
  width: 205px; height: 324px;
  margin: 0 auto;
}
.support-banner-mark img{width: 100%; height: 100%; object-fit: contain;}

/* helpdesk */
.help-desk {
  padding: 110px 0;
  background-image: url("../src/assets/images/plane-support.png");
 background-size: 70%;
 background-position: left top;
 background-repeat: no-repeat;
}


.helpdesk .help-desk-color{color: #001558;}
.helpdesk-img {
  width: 437px;
  height: 334px;
  margin: 0 auto;
}
.helpdesk-img img{width: 100%; height: 100%; object-fit: contain;}
.help-desk .helpdesk p {
  font-size: 20px;
  color: rgb(0 0 0 / 80%);
}
.top-support-img{width: 1243px; height: 192px;}
.top-support-img img{width: 100%; height: 100%; object-fit: contain;}


/* ouerhelp */
.our-help {
  background: #FFFFFF;
  box-shadow: 0px 0px 16px #00000014;
  border-radius: 10px;
  padding: 60px 30px 70px 0px;
  margin-bottom: 100px;
}
.our-help-content {
  padding-right: 40px;
}
.ourhelp h4 {
  margin-bottom: 30px;
  margin-left: 30px;
}
.ourhelp h4::before {
  width: 280px;
  color: #FA6400;
  position: absolute;
  left: 0px;
  border: 1px solid rgb(250 100 0 / 30%);
  content: "";
  bottom: -6px;
}
.accordion-header h5 {
    font-size: 20px;
    color: rgb(0 0 0 / 80%);
    font-weight: bold;
    font-family: 'Poppins';
    padding: 0 0 0 120px;
    margin-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button{background-color: transparent;}
.accordion-flush .accordion-item .accordion-button:focus{border: 0; outline: 0;}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
  padding: 25px 0 ;
  box-shadow: none;
}
.accordion-button::after {
  background-image: url("../src/assets/images/Icon-plus.png");
  left: 80px;
  position: absolute;
  top: 26px;
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.accordion-button:not(.collapsed)::after{background-image: url("../src/assets/images/Icon-minus.png"); width: 18px;
  height: 14px;}
  
.our-help .accordion-body{padding: 0 35px 0px 75px;}
.accordion-item {border: 1px solid #fa640026;}
.ourhelp-form {
 background-color: #5ABB90;
  border-radius: 38px;
  padding: 40px 50px;
  box-shadow: 0px 7px 20px #0000001c;
}
.ourhelp-form h4 {
  color: #FFFFFF;
}
.ourhelp-form p {
  color: rgb(255 255 255 / 80%);
width: 356px;
margin: 0 auto 30px;
}
.accordion-body p::before {
  content: '';
  position: absolute;
  background-color: #FA6400;
  height: 80%;
  width: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: 115px;
 
}
.accordion-body p {
  padding: 0 0 0 60px;
  font-size: 17px;
}

.contact-form {
  width: 100%;
  margin: 0 auto;}
  .ourhelp-form .form-control {
    background: transparent;
    border-radius: 5px; padding: 15px;
    border: 1px solid rgb(255 255 255 / 60%);
    box-shadow: none;
    color: #fff;
}

.contact-form .form-label {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  font-family: 'Poppins';
}
#shop-description {
  background: transparent;
  border: 1px solid rgb(255 255 255 / 60%);
  width: 100%;
  border-radius: 8px;
  resize: none;
  box-shadow: none;
  outline: 0;
  padding: 8px;
  color: #fff;
}
.button-group-select.mobile-viewe{
  display: none;
}

/* contactus */
.contactus-banner{background-image: url("../src/assets/images/contact-banner.png"); background-repeat: no-repeat; background-size: cover;}
/* getin */
.getin {
  box-shadow: 0px 3px 16px #0000001a;
  background: #FFFFFF;
  padding: 60px 60px 70px 60px;
 margin-bottom: 100px;
}
.getin h4 {
  color: #263B4E;
}
.getin p {
  color: rgb(0 0 0 / 45%);
  padding: 0 30px 0 0px;
  margin-bottom: 45px;
}
.get-in .inputform input#inputPassword5 {
  border: 1px solid #BEBEBE;
  box-shadow: none;
  padding: 13px;
  border-radius: 7px;

}
.get-in .getin input#inputPassword5::placeholder {
  color: rgb(38 59 78 / 45%);
   padding: 0;
}
.getin-form {
  width: 100%;
}
.getin-form .form-control {
  padding: 13px;
  box-shadow: none;
  border: 1px solid #BEBEBE;
  color: #000;
  font-size: 16px;
}
.getin-form .form-control::placeholder {
  color: #BEBEBE;
}
#getin-description {
  width: 100%;
  border: 1px solid #BEBEBE;
  resize: none;
  border-radius: 7px;
  outline-color: #BEBEBE;
  padding: 13px;
  color: #000;
}
#getin-description::placeholder {
  padding: 0px;
  color: #BEBEBE;
  font-size: 16px;
}
/* popups */
.modal-content .about-country-flags-img-popups p {
  width: calc(100% - 45px);
  color: #000000;
}
.popups-p.modal-body p::before {
  content: '';
  position: absolute;
  background-color: #FA6400;
  height: 50%;
  width: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
}
.btn-close {
  position: absolute;
  background-image: unset!important; 
  top: -12px;
  right: -12px;
  background-color: #FE6C30;
  border: 1px solid #FE6C30;
  opacity: 1;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-close::before {
  content: '';
  position: absolute;
  background-image: url("../src/assets/images/close.svg") !important;
  transition: all ease-in-out 0.4s;
  height: 20px;
  width: 20px;
  background-size: 100%;
}
.btn-close:hover::before {
  transform: rotate(90deg);
}
button:focus:not(:focus-visible) {
  box-shadow: none;
}
.btn-close:hover{opacity: 1;}
.modal-footer{border-top: 0; padding: 20px 45px 20px 0;;}
.popups {
  display: flex !important;
  align-items: center;
  box-shadow: 0px 6px 16px #0000001A;
}
.modal.show .modal-dialog {
  transform: none;
  width: 100%;
  max-width: 650px;
}
.modal-header{
  border-bottom: 1px solid #707070;
  padding: 15px 45px 10px 50px;
  
}
.modal-title{
  font-size: 26px; 
  font-weight: 600;
  font-family: 'Poppins';
}
.popups-content {
  padding: 20px 54px 0 55px;
  overflow-x: auto;
  max-height: 320px;
}
.country-option img {
  width: 35px;
  margin-right: 15px;
}
.text-uppercase {
  text-transform: uppercase;
}
.scroll-btn {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 22px;
  border-radius: 50%;
  background-color: #FFF;
  border: 4px solid #fa640066;
  padding: 8px;
  transition: all ease-in-out 1s;
  height: 60px;
  width: 60px;
}
.scroll-btn svg {
  transform: rotate(270deg);  

}
.scroll-btn svg path {
  fill: #fa640066;
  transition: all ease-in-out 1s;
}
.scroll-btn:focus,.scroll-btn:active,.scroll-btn:hover{
  border-color: #FE6C30 !important;
  background-color: white !important;
}
.scroll-btn:hover svg path {
  fill: #FE6C30 !important;
}

.table-scroll{
  position: fixed;
  bottom: 0;
  right: 20px;
}
/* pagenotfound */
.page-found{
  padding: 140px 0 50px;
}
.page-wrapper a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}
.page-wrapper a:hover {
  color: #FE6C30;
}
/* Responsive */


/* 1700 */
@media screen and (max-width: 1700px){
  h1 {
    font-size: 80px;
    line-height: 80px;
  }
  h3 {
    font-size: 35px;
  }
  .container-width {
    max-width: 1320px;
  }
  /* header */
  .logo {
    width: 200px;
}
/* banner */
.banner {
  padding-top: 160px;
  background-size: 100% 100%;
  height: 840px;
}
.banner-content h1 {
  margin-bottom: 55px;
}
.banner-form {
  max-width: 1043px;
}
.banner-form input {
  font-size: 16px;
  padding: 20px 10px 20px 100px;
}
.privacy-banner {
  background-size: cover;
}
/* footer */ 
.footer .logo {
  margin: 0 auto;
}
.footer-social {
  margin-bottom: 50px;
}
.top-support-img {
  width: 1180px;
  height: 170px;
}
/* provider */
.sr-item-content-info h2 {
  font-size: 55px;
}
.privacy-boxes-content{height: 100%;}
.privacy-info {
  padding: 60px 0px;
}
.terms-banner {
  background-size: cover;
  /* padding: 180px 0; */
}
/* plans */
.plan-buy-box-content {
  /* padding: 30px 58px 46px 40px; */
  min-height: 400px;
}
.plan-buy-box-content-top h4 {
  font-size: 32px;
}
.plan-buy-box-content-top {
  left: 40px;
}
/* .plan-buy-box-content:nth-child(odd) {
  background-image: url('../src/assets/images/plan-buy-img-four.svg');
}
.plan-buy-box-content:nth-child(even) {
  background-image: url('../src/assets/images/plan-buy-img-three.svg');
} */
.plans-table-info{
  flex-direction: column;
  align-items:start !important;
}
.second-banner{
  margin-top: 70px;
}
.plans-banner {
  margin-top: 60px;
}
}
@media screen and (max-width: 1600px) {
  .contactus-banner {
    margin-top: 40px;

}
  
}
/* 1400 */
@media screen and (max-width: 1400px){
  h1 {
    font-size: 77px;
}
h2 {
  font-size: 40px;
}
  h3 {
    font-size: 32px;
}
p {
  font-size: 15px;
}
  .container-width {
    max-width: 1140px;
  }
  /* banner */
  .banner-form {
    max-width: 993px;
}
/* about */
.about {
  padding: 110px 0px 30px;
}
.about-content h2 {
  margin-bottom: 52px;
}
.about-content h2::before {
  bottom: -28px;
}
.about-content p {
  font-size: 19px;
}
.esim-description{
  font-size: 15px !important;

}
/* about boxes */
.about-boxes-wrapper {
  padding: 10px 0px 80px;
}
.footer-content p {
  width: 1083px;
  margin: 0px auto 32px;
}
.help-desk .helpdesk p{font-size: 18px;}
.support-banner-content p {font-size: 18px;}
.helpdesk-img {
  width: 370px;
  height: 300px;
  margin: 0 auto;
}
.top-support-img {
  width: 1100px;
  height: 150px;
}
.heading-form {
  font-size: 17px;
}
.ourhelp-form .form-control {
  padding: 11px;
}
.footer-social a {
  margin-right: 20px;
}
.contact-form .form-label{font-size: 16px;}
.ourhelp-form p {
  width: 320px;
  margin: 0 auto 30px;
}
/* support */
.sr-item-top {
  width: 280px;
}
/* .privacy-banner {
  padding: 130px 0px;
} */
.privacy-info {
  padding: 40px 0px;
}
.terms-banner{
  background-size: cover;
  /* padding: 130px 0; */
}
.mogo-data-boxes-content h6 {
  font-size: 22px;
}
.mogo-data-boxes-content p span {
  font-size: 32px;
}
.mogo-data-boxes-content p {
  font-size: 18px;
}
.mogo-data-boxes-price span {
  font-size: 24px;
}
.mogo-data-boxes-content {
  padding: 25px 30px;
}
.mogo-data-boxes {
  padding: 50px 0px;
}
.mogo-banner-content h6, .mogo-banner-content p {
  font-size: 18px;
}
.plan-offers-content-area {
  width: 1000px;
  margin: 0 auto;
}
.plan-offers-content-area-bottom h6 {
  font-size: 18px;
}
/* plans */
.plan-buy-box-content {
  padding: 30px 30px 26px 30px;
  background-size: 100%;
  min-height: 410px;
}
.plan-buy-box-content-top {
  left: 30px;
}
.plan-buy-box-content-top-img {
  width: 75px;
}
.plan-buy-box-content-top-img img {
  width: 100%;
  height: 100%;
}
.plan-buy-box-content-top h4 {
  font-size: 28px;
}
.header-search-select{
  width: 520px;
}

.contactus-banner {
  margin-top: 10px;
}
.country-search, .country-search-header {
  padding: 12px 10px;
}
.about-home {
  padding: 40px 0 30px;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-left.slick-arrow.slick-prev{
  left: -60px;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-right.slick-arrow.slick-next {
  right: -40px;
}
.page-found {
  padding: 120px 0 50px;
}
}
/* 1199 */
@media screen and (max-width: 1199px){
  h1 {
    font-size: 70px;
    line-height: 66px;
}
h2 {
  font-size: 36px;
}
  h3 {
    font-size: 29px;
}
h4 {
  font-size: 28px;
}
p {
  font-size: 14px;
}
  .container-width {
    max-width: 960px;
  }
  /* header */
  .header {
    padding: 13px 0px;
    background-color: white;
}
  .logo {
    width: 170px;
}
.dropdowns-wrapper .drop-curr-wrapper, .dropdowns-wrapper .drop-language-wrapper {
  padding: 9px 8px;
}
.dropdowns-wrapper .drop-language-wrapper {
  padding: 14px 12px !important;
}
/* banner */
.banner {
   margin-top: 80px;
  height: 800px;
}
.banner-content h1 {
  margin-bottom: 44px;
}
.banner-form {
  max-width: 843px;
}
.banner-form input {
  padding: 15px 10px 15px 100px;
}
/* about */
.about {
  padding: 100px 0px 20px;
}
.about-content h2 {
  margin-bottom: 45px;
}
.about-content p {
  font-size: 17px;
}
.esim-description{
  font-size: 14px !important;

}
.about-country-flags p {
  width: calc(100% - 25px);
}
.about-boxes-wrapper {
  padding: 10px 0px 50px;
}
/* footer */
.footer-social {
  margin-bottom: 40px;
}
.footer-links h6 {
  font-size: 15px;
  margin-right: 75px;
}
.footer-links .content-border::before {
  left: -40px;
}
.footer-links {
  margin-bottom: 35px;
}
.footer-content p {
  width: 883px;
  margin: 0px auto 30px;
}
.top-support-img {
  width: 970px;
  height: 140px;
}
.helpdesk-img {
  width: 330px;
  height: 290px;
  margin: 0 auto;
}
.help-desk .helpdesk p {
  font-size: 17px;
}
.accordion-header h5 {
  font-size: 19px;
}
.ourhelp-form p {
  width: 320px;
}
.ourhelp-form {
  padding: 40px 17px;
}
.sr-item-top {
  width: 240px;
}
/* support */
.sr-item-top span {
  font-size: 19px;
}
.sr-item-content-info h2 {
  font-size: 50px;
}
.plans-table th, .plans-table td {
  white-space: nowrap;
}
.plans-table th {
  font-size: 18px;
}
.plans-table td {
  font-size: 16px;
}
.getin p {
  margin-bottom: 40px;
}
.getin-form .form-control {
  padding: 10px;
  font-size: 15px;
}
#getin-description {
  padding: 10px;
}
/* .privacy-banner {
  padding: 108px 0px;
} */
/* .terms-banner {
  padding: 119px 0;
} */
.terms-banner .second-banner-content p, .second-banner-content p {
  line-height: 25px;
}
.mogo-data-boxes-price {
  bottom: 20px;
  padding: 6px 20px 6px 28px;
}
.mogo-data-boxes-content {
  padding: 22px 15px;
}
.mogo-data-boxes-price span {
  font-size: 22px;
}
.mogo-data-boxes-content p span {
  font-size: 29px;
  margin-right: 23px;
}
.mogo-data-boxes-content p {
  font-size: 17px;
}
.mogo-banner-content h2 {
  font-size: 75px;
  line-height: 80px;
}
.mogo-banner {
  padding: 110px 0px 50px 0;
}
.plan-offers-content-area {
  width: 850px;
  margin: 0 auto;
}
/* plans */
.plan-buy-box-content {
  /* padding: 30px 24px 15px 24px; */
  min-height: 380px;
}
.plan-buy-box-content-top-img {
  width: 60px;
}
.plan-buy-box-content-top h4 {
  font-size: 24px;
  width: calc(100% - 60px);
}
.plan-buy-box-content-bottom h6 {
  font-size: 18px;
}
.plan-buy-box-content-bottom h6 {
  font-size: 17px;
}
.plan-buy-box-content-bottom p {
  font-size: 15px;
}
.header-search-select {
    width: 400px;
}

.button-group-select.desktop-view{
  display: none;
}
.button-group-select.desktop-view{
  display: none;
}
.button-group-select.mobile-viewe{
  display: block;
  margin-bottom: 15px !important;
}
.button-group-select button{
  padding: 10px 18px;
}
.button-group-select{
  width: 100%;
  max-width: 500px;
  margin: 0 auto !important;

}
.second-banner {
  margin-top: 55px;
}
.contactus-banner {
  margin-top: 0px;

}
.plans-banner{
  height: 380px;
}
.about-home {
  padding: 40px 0 20px;
}
.plans-table{
  display: none;
}
.mobile-view-provider-list{
  display:block;
}
.mobile-view-provider-list .provider-data {
  background: #e9eaf4;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 25px 15px 15px 15px;
  cursor: pointer;
}
.mobile-view-plans p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mobile-view-plans .mobile-text {
  width: 180px;
}
.plans-table-img{
  width: 32px;
  height: 32px;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-right.slick-arrow.slick-next {
  top: 180px;
  padding: 10px;
  right: -10px;

}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-left.slick-arrow.slick-prev{
  top: 180px;
  padding: 10px;
  left: -30px;
 
}

.slider-img{
  width: 250px;
  margin: 0 auto;
}
.slider-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
}
/* 991 */
@media screen and (max-width: 991px){
  h1 {
    font-size: 70px;
    line-height: 54px;
}
  h3 {
    font-size: 27px;
}
  .container-width {
    max-width: 720px;
  }
  /* banner */
  .banner-content h1 {
    margin-bottom: 35px;
}
.banner-form {
  max-width: 713px;
}
.banner {
  margin-top: 80px;
  height: 660px;
}
/* about */
.about {
  padding: 100px 0px 10px;
}
.about-content h2 {
  margin-bottom: 38px;
}
.about-content h2::before {
  bottom: -22px;
}
.about-boxes-wrapper {
  padding: 10px 0px 30px;
}
/* footer */
.footer-social, .footer-links {
  margin-bottom: 30px;
}
.footer-content p {
  width: 713px;
  margin: 0px auto 28px;
  font-size: 15px;
}
.accordain {
  margin-bottom: 40px;
}
.ourhelp-form {
  margin-left: 30px;
  padding: 40px;
}
.accordion-header h5 {
  font-size: 18px;
}
.top-support-img {
  max-width: 900px;
  height: 140px;
  margin: 0 auto;
  width: 100%;
}
.support-banner-mark {
  width: 170px;
  height: 290px;
  margin: 0 auto 30px;
}
.support-banner-content p {
  font-size: 15px;
}
.help-desk {
  padding: 110px 0;
  background-size: 90%;
}
.help-desk .helpdesk p {
  font-size: 15px;
}
/* .second-banner {
  padding: 107px 0px;
  background-size: 100% 100%;
} */
.heading-form {
  font-size: 15px;
}
.ourhelp-form .form-control {
  padding: 9px;
}
.get-in .inputform input#inputPassword5 {
  padding: 10px;
  font-size: 14px;
}
/* support */
.second-banner-content {
  width: 700px;
}
.second-banner-content p {
  font-size: 19px;
  line-height: 32px;
}
.sr-item-content-info h2 {
  font-size: 45px;
}
/* .contactus-banner {
  padding: 100px 0 100px 0;
} */
.getin {
  padding: 45px 30px 45px 30px;
  margin-bottom: 80px;
}
.privacy-banner {
  /* padding: 85px 0px; */
  background-size: cover;
}
.modal-header {
  padding: 15px 42px 10px 30px;
}
.popups-content {
  padding: 20px 54px 0 35px;
}
.terms-banner {
  /* padding: 140px 0 90px; */
  background-size: cover;
}
.privacy-boxes-content-img{text-align: center; margin-bottom: 30px;}
.terms-info .privacy-info-content p {
  font-size: 17px;
}
.mogo-banner-content p {
  margin-bottom: 20px;
}
.mogo-banner-content h6, .mogo-banner-content p {
  margin-bottom: 20px;
}
/* .mogo-banner {
  padding: 60px 0px;
} */
.mogo-banner {
  padding: 100px 0px 50px 0;
}
.plan-offers-content-area {
  width: 730px;
  margin: 0 auto;
}
.plan-offers-content-area-bottom h6 {
  font-size: 17px;
}
.mogo-banner-content h2 {
  font-size: 70px;
  line-height: 75px;
}
.orange-btn-large{
  padding: 10px 20px;
  font-size: 16px;
}
.mogo-banner-img img {
  display: none;
}
.mogo-banner-img-mobile {
  display: block;
  position: absolute;
  opacity: 0.3;
  width: 545px;
  right: -112px;
  bottom: 0px;
}
/* plans */
.plan-buy-box-content {
  background-image: unset !important;
  /* width: 50%; */
  padding: 30px 42px 26px 40px;
  margin-bottom: 20px;
  min-height: 300px;
}
.plan-buy-box-content-top {
  left: 0px;
  position: unset !important;
  margin-bottom: 20px;
  justify-content: center;
}
.plan-buy-box-content-top h4 {
  width: unset;
}
/* .plan-buy-box-content-bottom.border-blue p, .plan-buy-box-content-bottom.border-green p {
  border: 2px solid #001558;
  border-radius: 6px;
  padding: 15px;
} */
/* .plan-buy-box-content-bottom.border-green p {
  border: 2px solid #5ABB90;
  border-radius: 6px;
  padding: 15px;
} */
.plan-buy {
  padding-bottom: 40px;
}
.banner-form .input-wrapper-area .css-13cymwt-control{
  padding: 18px 75px;
}
.banner-search-img{
  left:35px;
}
.orange-btn.mobile-view{
  display: block;
}
.orange-btn.desktop-view{
  display: none;
}
.about-boxes-content p{
  margin-bottom: 0;
}
.header-search-select.mobie-view{
  display: block !important;
  width: 100%;
  margin-top: 10px;

}
.header-search-select.desktop-view{
  display: none;
}
.header-search-select .search-img {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 20px;
}

.banner-form .input-wrapper-area .css-13cymwt-control, .banner-form .input-wrapper-area .custom__control{
  padding: 10px 10px 13px 60px!important;
}
.about-home {
  padding: 30px 0 10px;
}
.plans-banner{
  padding: 30px 0 0 0;
}
.support-banner{
  margin-top: 80px;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-right.slick-arrow.slick-next {
  right: 0px;

}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-left.slick-arrow.slick-prev{
  left: 0px;
 
}
.slider-img {
  width: 160px;
  margin: 0 auto;
}

}
/* 767 */
@media screen and (max-width: 767px){
  h1 {
    font-size: 53px;
    line-height: 48px;
}
h2 {
  font-size: 36px;
}
  h3 {
    font-size: 24px;
}
h4 {
  font-size: 25px;
}
  .container-width {
    max-width: 540px;
  }
  /* header */
  .logo {
    width: 135px;
}
.dollar-icon {
  padding: 6px 9px;
  font-size: 13px;
}
.drop-curr h6, .drop-language-wrapper h6 {
  font-size: 14px;
}
.dropdowns-wrapper .drop-curr-wrapper, .dropdowns-wrapper .drop-language-wrapper {
  padding: 8px 8px;
}
.dropdowns-wrapper .drop-language-wrapper {
  padding: 13px 12px !important;
}
/* banner */
.banner {
  position: relative;
  z-index: 1;
  height: 550px;
  margin-top: 30px;
}
.banner::before {
  position: absolute;
  content: '';
  background-color: #ffffffa6;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}
.banner-content h1 {
  margin-bottom: 25px;
}
/* about */
.about-boxes {
  padding: 22px 27px;
  margin-bottom: 20px;
}
.about-boxes-content p {
  margin-bottom: 0px;
}
/* footer */
.footer {
  padding-top: 30px;
}
.footer-social, .footer-links {
  margin-bottom: 25px;
}
.footer-links h6 {
  font-size: 14px;
  margin-right: 45px;
}
.footer-links .content-border::before {
  left: -25px;
}
.footer-content p {
  width: 503px;
  margin: 0px auto 25px;
}
.footer-copyright p {
  font-size: 15px;
}
.footer-copyright {
  padding: 15px 0px;
}
.ourhelp-form {
  padding: 30px;
  margin-left: 0px;
}
.our-help {
  padding: 60px 15px 30px 15px;
  margin-bottom: 100px;
}
.accordion-header h5 {
  font-size: 15px;
  padding: 0 0 0 80px;
}

.helpdesk-img {
  width: 270px;
  height: 200px;
  margin: 0 auto;
}
.accordion-body p::before {
  left: 80px;
}
.ourhelp h4::before {
  width: 210px;
 
}
.accordion-body p{padding: 0 0 0 30px}
.accordion-button::after{left: 45px; top: 23px;}
.getin-form .form-control {
  font-size: 13px;
}
.getin {
  padding: 30px 20px 30px 20px;
  margin-bottom: 60px;
}
.getin p {
  margin-bottom: 30px;
  padding: 0;
}
.get-from {
  display: block !important;
}
.help-desk {
  background-size: 95%;
}
.accordion-button:not(.collapsed)::after, .accordion-button::after {
  width: 14px;
}
/* support */
/* .second-banner {
  padding: 87px 0px;
  background-size: 100% 100%;
} */
.second-banner-content {
  width: 100%;
}
.second-banner-content p {
  font-size: 17px;
  line-height: 29px;
}
.sr-item-top {
  width: 350px;
}
.plans-table th {
  font-size: 17px;
}
.plans-table-img {
  width: 30px;
}
.privacy-legal ul li, .privacy-list li{font-size: 17px; line-height: 30px;}
.cookie-policy .form-switch .form-check-input {
  width: 100px;
  height: 45px;
}
.privacy-banner {
  /* padding: 80px 0px; */
  background-size: cover;
}
.privacy-info {
  padding: 35px 0px;
}
.privacy-legal h4 {
  margin-bottom: 25px;
}
.privacy-legal ul::before {
  height: 95%;
}
.privacy-boxes-content{padding: 15px 30px 25px 30px;}
.btn-close{top: -7px; right: 0px;}
.modal.show .modal-dialog {
  width: 100%;
  max-width: 550px;
}
.terms-banner {
  /* padding: 110px 0; */
  background-size: cover;
  height: 445px;
}
.mogo-data-boxes-content p span {
  font-size: 26px;
}
.mogo-data-boxes-content-heading {
  margin-bottom: 20px;
}
.plan-offers-content-area {
  width: 100%;
  margin: 0 auto;
}
.plan-offers-content-area-bottom h6 {
  font-size: 16px;
  line-height: 22px;
}
.mogo-banner-content h2 {
  font-size: 60px;
  line-height: 75px;
}
.mogo-banner-content h6, .mogo-banner-content p {
  font-size: 17px;
}
.mogo-data-boxes {
  padding: 40px 0px;
}
.box-heading {
  padding: 0 27px;
}
.mobile-view-plans .mobile-text {
  width: 140px;
}

}

/* 575 */
@media screen and (max-width: 575px){
  h1 {
    font-size: 42px;
    line-height: 43px;
}
h2 {
  font-size: 34px;
}
  h3 {
    font-size: 21px;
}
  .container-width {
    max-width: 100%;
    padding: 0px 15px;
  }
  /* header */
  .header {
    padding: 10px 0px;
}
  .web-dd {
    display: none;
  }
  .mobile-dd {
    display: block;
  }
  .logo {
    width: 100px;
}
.drop-curr, .dropdowns-wrapper .drop-language-wrapper h6 {
  margin-right: 25px;
}
.dropdowns-wrapper .drop-curr-wrapper::after, .dropdowns-wrapper .drop-language-wrapper::after {
  right: 13px;
}
  /* banner */
.banner {
  height: auto;
  background-size: cover;
  background-position: left;
  padding: 80px 0px;
}
.banner-form {
  max-width: 100%;
}
.banner-search-img {
  left: 20px;
  width: 18px;
}
.banner-form input {
  padding: 13px 10px 13px 55px;
  font-size: 14px;
}
.banner-content h1 {
  margin-bottom: 15px;
}
/* about */
.about-boxes-wrapper {
  padding: 10px 0px 20px;
  margin: 0px 10px;
}
.about-boxes {
  padding: 20px 22px !important;
}
/* footer */
.footer {
  padding-top: 20px;
}
.footer .logo {
  width: 120px;
}
.social-icons-img {
  height: 38px;
  width: 38px;
}
.footer-social, .footer-links {
  margin-bottom: 22px;
}
.footer-social a {
  margin-right: 18px;
}
.footer-links h6 {
  margin-right: 0px;
  margin-bottom: 12px;
}
.footer-links .content-border::before {
  display: none;
}
.footer-content p {
  width: 100%;
  margin: 0px auto 20px;
  font-size: 14px;
}
.footer-copyright p {
  font-size: 14px;
}
.ourhelp-form p {
  max-width: 100%;
  width: 100%;
  margin: 0 auto 20px;
}
.support-banner-mark {
  width: 130px;
  height: 200px;
  margin: 0 auto 30px;
}
.helpdesk-img {
  width: 220px;
  height: 150px;
  margin: 0 auto;
}
.our-help {
  margin-bottom: 60px;
  padding: 20px 15px;
}
.heading-form {
  font-size: 13px;
}
.ourhelp-form {
  padding: 18px;
}
.our-help-content {
  padding-right: 0px;
}
.accordion-button:not(.collapsed)::after, .accordion-button::after {
  width: 12px;
  top: 19px;
}
/* support */
.second-banner {
 height: 520px;

}
.sr-item-top {
  width: 210px;
}
.plans-slider {
  padding: 40px 0px;
}
.sr-item-content-info h2 {
  font-size: 40px;
}
.sr-item-content-info h6 {
  font-size: 18px;
  margin-bottom: 22px;
}
.dropdowns-wrapper .drop-curr-wrapper, .dropdowns-wrapper .drop-language-wrapper {
  padding: 12px 8px;
  width: 100%;
}
.privacy-legal ul li, .privacy-list li {
  font-size: 16px;
}
.privacy-info {
  padding: 30px 0px;
}
.privacy-banner {
  /* padding: 80px 0px; */
  background-size: cover;
  height: 320px;
}
.about-content p, .privacy-info-content p {
  font-size: 16px;
}
.privacy-legal-boxe{padding: 15px;}
.cookie-policy .form-switch .form-check-input {
  width: 75px;
  height: 35px;
}
.privacy-list-img {
  width: 15px;
}
.privacy-legal h4 {
  margin-bottom: 15px;
}
.modal.show .modal-dialog {
  width: 100%;
  max-width: 85%;
  margin: 0 auto;
}
/* .terms-banner {
  padding: 110px 0;
} */
.privacy-boxes-content-info{
  width: 100%;
  text-align: center;
}
.privacy-boxes-content-img{ width: 100%;  text-align: center; margin-bottom: 30px;}
.dropdown-menu.show {
  width: 190px;
  left: -116px !important;
}
.dropdwon-top-clip-img {
  top: -19px;
}
.cookie-policy-content{text-align: center;}
.form-switch{text-align: center;}

.mogo-banner-content h2 {
  font-size: 54px;
  line-height: 60px;
}
.mogo-banner-content h6, .mogo-banner-content p {
  font-size: 16px;
}
.plan-offers-content {
  padding: 20px 15px;
}
.mogo-banner-content{width: 100%; text-align: center;}
.mogo-banner-icon{text-align: center; margin-bottom: 20px;}
.orange-btn-large{margin: 0 auto;}
/* .mogo-banner {
  padding: 40px 0px;
} */
.mogo-data-boxes-content p span {
  font-size: 22px;
}
.mogo-data-boxes-content p {
  font-size: 16px;
}
.mogo-data-boxes-content-heading {
  font-size: 20px;
}
.mogo-banner-img-mobile {
  top: 50%;
  transform: translateY(-50%);
  right: -100px;
  bottom: 50px;
}
.header-btn-color.dropdown-menu.show {
  background-color: #263B4E !important;
  color: #fff;
}
/* plans */
.plan-buy {
  padding-bottom: 20px;
}
.plan-buy-box-content {
  width: 100%;
  padding: 25px 22px;
  margin-bottom: 20px;
}
.mogo-data-boxes-heading, .plan-buy-heading {
  padding: 0px 15px;
}
.mogo-data-boxes {
  padding: 30px 0px;
}
.banner-form .input-wrapper-area .css-13cymwt-control{
  padding: 12px 45px;
}
.plans-dropdowns .dropdown-menu.show {
  left: 0px !important;
}
.plans-table-wrapper .dropdowns-wrapper .dropdown-menu.show{
  /* top: 4px !important; */
  transform: translate(-20%, 10px) !important;
}
.plans-table-info{
  align-items: center !important;
}
.button-table{
  justify-content: center !important;
} 

.banner-form .input-wrapper-area .css-13cymwt-control, .banner-form .input-wrapper-area .custom__control{
  padding: 10px 2px 8px 40px!important;;
}
/* .support-banner{
  padding: 90px 0;
} */
/* .contactus-banner {
  padding: 90px 0 100px 0;
} */
.terms-banner {
  height: 390px;
}
.plans-banner {
  height: 400px;
}
.box-heading {
  padding: 0 22px;
}
.button-group-select.mobile-viewe{
  display: flex;
  overflow-x: auto;
  max-width: 500px;
}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-right.slick-arrow.slick-next {
 right: -10px;

}
.plan-buy .pro-slider svg.svg-inline--fa.fa-chevron-left.slick-arrow.slick-prev{
  left: -10px;

}
.dollar-icon {
  padding: 3px 5px;
  font-size: 11px;
}
.mobile-dd .showdropdown .dropdown-item:focus, .dropdown-item:hover{
  background-color: unset;
  color: #000;
}
.header-btn-color .dropdown-item:hover h6{
  color: #fff;
}
.header .header-content .dropdowns-wrapper .dropdown-menu.show {
  width: 230px;
}
.mogo-banner {
  padding: 70px 0px 50px 0;
}
.page-found {
  padding: 100px 0 40px;
}

}
@media (max-width:479px) {
  .ourhelp h4 {
    margin-left: 0px;
}
.accordion-header h5 {
  padding: 0 0 0 30px;
}
.accordion-body p {
  font-size: 11px;
}
.support-banner-content p {
  font-size: 11px;
}
.help-desk .helpdesk p {
  font-size: 11px;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  padding: 17px 0;
}
.accordion-button::after {
  left: 0;
}
.contact-form .form-label {
  font-size: 14px;
}
.our-help .accordion-body {
  padding: 0 20px 0px 20px;
}
.accordion-body p::before {
  left: 30px;
}
.second-banner-content {
  margin: 0 auto 25px;
}
.social-icons-img-banner{
  width: 38px;
  height: 38px;
}
/* .contactus-banner {
  padding: 80px 0 50px 0;
} */
.cookie-policy {
  padding: 20px 10px;
}
.cookie-policy .form-switch .form-check-input {
  width: 70px;
  height: 30px;
}
.privacy-banner {
 background-position: center;
}
.privacy-info {
  padding: 25px 0px;
}
.privacy-info-content h2 {
  font-size: 28px;
}
.privacy-boxes-content h4 {
  font-size: 25px;
}
.privacy-banner p {
  line-height: 22px;
}
.privacy-legal ul li, .privacy-list li
{
  line-height: 25px;
}
/* .terms-banner {
  padding: 100px 0;
} */
.header .header-content .dropdowns-wrapper .dropdown-menu.show {
  left: -95px!important;
  width: 150px;
}
.dropdwon-top-clip-img{
  right: 15px;
}


}